import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE
} from "react-admin";
import uuid from 'react-uuid';
import {selectModifiedValues, stripAuthHeaders, decryptHereKey, errorHandler, filterResources, getBillingAccountByUUID, handleEmpty } from "./DataProviderUtils";

const API_URL = ((window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
                (process && process.env && process.env.REACT_APP_API_URL));

const PLATFORM_API_URL = API_URL+"/api/v1";

// default format for outgoing requests
const baseOutgoingRequest = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = new URLSearchParams({
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      });
      return { url: `${API_URL}/${resourcePath}?${query.toString()}` };
    }
    case GET_ONE:
      return { url: `${API_URL}/${resourcePath}/${params.id}` };
    case GET_MANY: {
      const query = new URLSearchParams({
        filter: JSON.stringify({ id: params.ids }),
      });
      return { url: `${API_URL}/${resourcePath}?${query.toString()}` };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = new URLSearchParams({
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
      });
      return { url: `${API_URL}/${resourcePath}?${query.toString()}` };
    }
    case UPDATE:
      return {
        url: `${API_URL}/${resourcePath}/${params.id}`,
        options: {method: "PATCH", body: JSON.stringify(params.data)}
      };
    case CREATE:
      return {
        url: `${API_URL}/${resourcePath}`,
        options: { method: "POST", body: JSON.stringify(params.data) },
      };
    case DELETE:
      return {
        url: `${API_URL}/${resourcePath}/${params.id}`,
        options: { method: "DELETE" },
      };
    default:
      return Promise.reject("data provider request failed");
  }
};

// base outgoing platform api requests (forms, screens, etc.)
const outgoingClientResource = (type, resourcePath, params) => {
  var options = {};
  options.headers = new Headers();
  options.headers.set("eleos-admin-client-key", params.id);
  switch (type) {
    case GET_LIST: {
      return { url: `${PLATFORM_API_URL}/${resourcePath}`,
        options};
    }
    case GET_ONE: {
      return { url: `${PLATFORM_API_URL}/${resourcePath}`,
        options};
    }
    case GET_MANY: {
      return { url: `${PLATFORM_API_URL}/${resourcePath}`,
			  options};
    }
    case GET_MANY_REFERENCE: {
      return { url: `${PLATFORM_API_URL}/${resourcePath}`,
        options};
    }
    default:
      return Promise.reject("data provider request failed")
  }
}

const outgoingBackendUser = (type, resourcePath, params) => {
  var options = {};
  options.headers = new Headers();
  if (type === "GET_MANY_REFERENCE") {
    if (params.target === "key") {
      return { url: `${API_URL}/internal/clients/${params.id}/${resourcePath}`,
      options};
    }
    else if (params.target === "uuid") {
      return { url: `${API_URL}/internal/customers/${params.id}/${resourcePath}`,
      options};
    }
    else {
      return Promise.reject("invalid target")
    }
  }
  else if (type === "GET_ONE") {
    return {
      url: `${API_URL}/internal/backend_users/${params.id}`,
      options
    };
  }
  else if (type === "UPDATE") {
    if (params.data.active!=null){
      return {
        url: `${API_URL}/internal/backend_users/${params.id}`,
        options: { method: "PATCH", body: JSON.stringify(params.data)}
      }
    } else if (params.previousData.record !== undefined) {
      return{
        url: `${API_URL}/internal/backend_users/${params.previousData.record.id}/roles`,
        options: { method: "PATCH", body: JSON.stringify(params.data)},
      }
    } else {
      return {
        url: `${API_URL}/internal/backend_users/${params.previousData.id}`,
        options: { method: "PATCH", body: JSON.stringify({
          allow_ftp_downloads:params.data.allow_ftp_downloads
        })},
      }
    }
  }
  else {
      return Promise.reject("data provider request failed")
  }
}

const incomingBackendUsers = (json, type, params) => {
  switch(type){
    case GET_MANY_REFERENCE:
      json.map(x => {
        Object.assign(x, {"id": x.email})
      });
      return {
        data: json,
        total: json.length
      }
    case UPDATE:
      return {
        data: {"id": json.email},
      }
    case GET_ONE:
      return {
        data: Object.assign(json, {"id": json.email})
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

// default format for incoming request data
const baseIncomingRequest = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      return {
        data: json,
        total: json.length
      }
    case GET_MANY_REFERENCE:
      const data = json.length === undefined ? [json] : json;
      return {
        data,
        total: json.length
      }
    case GET_ONE:
      return {
        data: json
      }
    case CREATE:
      return {
        data: { ...params.data }
      }
    case UPDATE:
      return {
        data: { ...params.data }
      }
    default:
      return { data: json}
  }
};

const outgoingClients = (type, params) => {
  switch (type) {
    case UPDATE: {
        var payload = selectModifiedValues(params.previousData, params.data);
        payload = (payload == null ? {} : payload);
        if (payload.apns_app === null) {
          payload.apns_app = {
            private_key: null,
            certificate: null
          }
        }
        return {
          url: `${API_URL}/clients/${params.id}`,
          options: { method: "PATCH", body: JSON.stringify(payload)},
        };
    }
    case GET_MANY_REFERENCE:
      return baseOutgoingRequest(type, 'clients', params);
    case CREATE: {
      if (params.data.role === "sandbox" && !params.data.key.endsWith("-dev")) {
        alert("A client of type 'sandbox' must have a client key ending with '-dev'")
        return {}
      }
      else if (params.data.role === "guest") {
        if (!params.data.key.endsWith("-guest") || !params.data.name.endsWith("(guest)")) {
          alert("A client of type 'guest' must have a client key ending with '-guest' and a name ending with '(guest)'");
          return {}
        }
        else if (!params.data.client_group_key) {
          alert("A guest client must be within an existing client group.");
          return {};
        }
      }
      return {
        url: `${API_URL}/internal/clients`,
        options: { method: "POST", body: JSON.stringify(params.data) },
      };
    }
    default: {
      return baseOutgoingRequest(type, 'clients', params);
    }
  }
}

const parseClientResourceAttribute = (json, resource_attribute, resource) => {
  json.forEach(function (object) {
    if(object.hasOwnProperty(resource_attribute) && typeof object[resource_attribute] === "string"){
      try{
        object[resource_attribute] = JSON.parse(object[resource_attribute]);
      }catch(e){
        console.error(`Could not parse the ${resource} ${resource_attribute} string: ${object[resource_attribute]}`);
      }
    }
  });
}

const parseClientErrors = (json) => {
  json.forEach(function (object) {
    switch (object.type) {
    // grab a readable error messages for each error type
    // schema error will be left as is
      case 'json':
        object["error_details"] = object.json_error.message;
        break;
      case 'http':
        object["error_details"] = `Expected http status: ${object.http_error.expected_status}. Actual http status: ${object.http_error.actual_status}`
        object["http_status"] = object.http_error.actual_status
        break;
      case 'network':
        object["error_details"] = object.network_error.message;
        break;
      case 'other':
        object["error_details"] = object.other_error.message;
        break;
      default:
        object["error_details"] = "";
    }
    stripAuthHeaders(object);
    // sets the id for the object, requried by react iterators
    object['id'] = object.uuid;
    // pulls out the service name from the service object if it exists
    if (object.service && object.service.name) {
     object['service_name'] = object.service.name
    }
  });
}

const incomingClientResource = (json, type, resource, params) => {
  if(type !== "GET_ONE") {
    json.map(x => {
      if(x.id == null) {
        if (resource === 'backend_users') {
          Object.assign(x, {"id": x.email})
        }
        else {
          Object.assign(x, {"id": uuid()})
        }
      }
    });
    switch (resource) {
      case 'errors':
        parseClientErrors(json);
        break;
      case 'menus':
      case 'dashboards':
        parseClientResourceAttribute(json,"items",resource);
        break;
      case 'backend_users':
        if (type == "UPDATE") {
          return {
            data: Object.assign(json, {"id": uuid()})
          }
        }
        else {
          parseClientResourceAttribute(json, "roles", resource);
        }
        break;
      default:
        parseClientResourceAttribute(json,"definition",resource);
    }
  }
  return baseIncomingRequest(json, type, params);
}

const incomingClients = (json, type, params) => {
  switch (type) {
    case CREATE:
      var newJson = Object.assign(json, {"id": json.key });
      return ({data: newJson})
    case GET_LIST:
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.key.toLowerCase().includes(query) || 
          (item.name && item.name.toLowerCase().includes(query))
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.key })),
        total: json.length,
      }
    case GET_MANY_REFERENCE:
      if(params.target === 'billing_account_id') {
          return {
            data: json.filter(x => x.billing_account_id === params.id).map(x => Object.assign(x, { "id": x.key })),
            total: json.length
          }
    } else {
        return {
          total: json.length,
          data: json.map(x => Object.assign(x, { "id": x.key }))
        }}
    case GET_ONE:
      return {
        data: Object.assign(json, {"id": json.key})
      }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, {"id": x.key })),
        total: json.length,
      }
    case UPDATE:
        return {data: {"id": json.key},}
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingAppleAccounts = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, {"id": x.apple_team_identifier})),
        total: json.length
      }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, {"id": x.apple_team_identifier})),
        total: json.length
      }
    case GET_ONE:
      return {
        data: Object.assign(json, {"id": json.apple_team_identifier})
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingClientGroups = (json, type, params) => {
  switch (type) {
    case CREATE:
      var newJson = Object.assign(json, {"id": json.key });
      return ({data: newJson})
    case GET_ONE:
      return {
        data: Object.assign(json, {"id": json["key"]})
      }
    case GET_LIST:
      // this is to handle AutocompleteInputs, we actually don't want to do any manually filter if the filter is from an automcomplete input
      // we check if this request is from an autocomlete input by seeing if the 'q' key is in the filter object, otherwise filter like normal
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.key.toLowerCase().includes(query) || 
          (item.name && item.name.toLowerCase().includes(query))
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.key})),
        total: json.length
      }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, {"id": x.key})),
        total: json.length
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const getBillingAccount = (json, params) => {
  const account = json.filter(x => x.external_id === params.id)
  if (account.length < 1 || account.length > 1) return {}
  else return account[0]
}
const getContractStructure= (json, params) => {
  const structure = json.filter(x => x.code === params.id)
  if (structure.length < 1 || structure.length > 1) return {}
  else return structure[0]
}

const incomingBillingAccounts = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      json = [getBillingAccountByUUID(json, params.id),]
      return {
        data: json.map(x => Object.assign(x, { "id": x.name })),
        total: json.length
      }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.external_id })),
        total: json.length
      }
    case GET_LIST:
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.name.toLowerCase().includes(query)
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.external_id })),
        total: json.length,
      }
    case GET_ONE:
      const billingAccount = getBillingAccount(json, params)
      return {
        data: Object.assign(billingAccount, { "id": billingAccount.external_id }),
      }
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.name }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingAccounts = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      case GET_ONE:
        return { url: `${API_URL}/${resourcePath}`};
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const incomingBillingContractStructures = (json, type, params) => {
  switch (type) {
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.code })),
        total: json.length
      }
    case GET_LIST:
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.code.toLowerCase().includes(query)
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.code })),
        total: json.length,
      }
    case GET_ONE:
      const structures = json.filter(x => x.code === params.id)
      const structure = (structures.length < 1 || structures.length > 1) ? {} : structures[0]
      return {
        data: Object.assign(structure, { "id": structure.code }),
      }
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.code }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingContractStructures = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      case GET_ONE:
        return { url: `${API_URL}/${resourcePath}`};
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const incomingBillingContractStructureProducts = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      return {
        total: json.length,
        data: json.map(x => Object.assign(x, { "id": x.product_code }))
      }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.contract_structure_code })),
        total: json.length
      }
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, {"id": x.contract_structure_code })),
        total: json.length,
      }
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.contract_structure_code }),
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingContractStructureProducts = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY_REFERENCE:
        return { url: `${API_URL}/${resourcePath}/${params.filter.id}`};
      case GET_LIST:
        return { url: `${API_URL}/${resourcePath}/${params.filter.id}`};
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const incomingPerLoadBillingStructure = (json, type, params) => {
  switch (type) {
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.billing_account_external_id }),
      };
    case UPDATE:
      return {
        data: Object.assign(json, { "id": json.billing_account_external_id }),
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingPerLoadBillingStructure = (type, resourcePath, params) => {
  switch (type) {
    case CREATE:
      return {
        url: `${API_URL}/${resourcePath}`,
        options: { method: "POST", body: JSON.stringify(params.data) },
      };
    case UPDATE:
      return {
        url: `${API_URL}/${resourcePath}`,
        options: { method: "PATCH", body: JSON.stringify(params.data) },
      };
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingBillingAccountContracts = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
        return {
          total: json.length,
          data: json.map(x => Object.assign(x, { "id": x.external_id }))
        }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.external_id })),
        total: json.length
      }
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, {"id": x.external_id })),
        total: json.length,
      }
    case GET_ONE:
      let acct_contract = json.filter(x => x.external_id === params.id)
      (acct_contract.length < 1 || acct_contract.length > 1) ? acct_contract = {} : acct_contract = acct_contract[0]
      return {
        data: Object.assign(acct_contract, { "id": acct_contract.id }),
      }
    case UPDATE:
      return {
        data: Object.assign(json, { "id": json.billing_account_external_id }),
        options: { method: "PATCH", body: JSON.stringify(params.data) },
      };  
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.billing_account_external_id }),
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingAccountContracts = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      case GET_MANY_REFERENCE:
        return { url: `${API_URL}/${resourcePath}/${params.id}`};
      case GET_LIST:
        return { url: `${API_URL}/${resourcePath}/${params.id}`};
      case UPDATE:
        return {
          url: `${API_URL}/${resourcePath}/${params.id}`,
          options: { method: "PATCH", body: JSON.stringify(params.data) },
        };
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const incomingBillingProducts = (json, type, params) => {
  switch (type) {
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.code })),
        total: json.length
      }
    case GET_LIST:
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.code.toLowerCase().includes(query)
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.code })),
        total: json.length,
      }
    case GET_ONE:
      return { 
        data: {...json[0], id: json[0].code },
      }
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.code }),
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingProducts = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      case GET_ONE:
        return { url: `${API_URL}/${resourcePath}/${params.id}`};
      case CREATE:
        let body = { ...params.data };
        /*
         *  Squid only accepts raw JSON for application_filters.
         *  Because of this, we can not just JSON.stringify the body. 
         *  Check and convert application_filters to raw JSON       
         */
        if (body.application_filters) {
          body.application_filters = JSON.parse(body.application_filters);
        }
        return {
          url: `${API_URL}/${resourcePath}`,
          options: { method: "POST", body: JSON.stringify(body)},
        };
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const incomingBillingPriceSheets = (json, type, params) => {
  switch (type) {
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.code })),
        total: json.length
      }
    case GET_LIST:
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.code.toLowerCase().includes(query)
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.code })),
        total: json.length,
      }
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.code})
      }
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.code }),
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingPriceSheets = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      case CREATE:
        return { 
          url: `${API_URL}/${resourcePath}`,
          options: {method: "POST", body: JSON.stringify(params.data)}}
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const incomingBillingPriceSheetItems = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
        return {
          total: json.length,
          data: json.map((x, index) => Object.assign(x, { "id": index + 1 }))
        }
    case GET_MANY:
      return {
        total: json.length,
        data: json.map((x, index) => Object.assign(x, { "id": index + 1 }))
      }
    case GET_LIST:
      return {
        total: json.length,
        data: json.map((x, index) => Object.assign(x, { "id": index + 1 }))
      }
    case CREATE:
      return {
        data: Object.assign(json, { "id": json.price_sheet_code})
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBillingPriceSheetItems = (type, resourcePath, params) => {
  switch (type) {
      case GET_MANY:
        return { url: `${API_URL}/${resourcePath}`};
      case GET_MANY_REFERENCE:
        return { url: `${API_URL}/${resourcePath}/${params.id}`};
      case GET_LIST:
        return { url: `${API_URL}/${resourcePath}/${params.id}`};
      case UPDATE:
        return {
          url: `${API_URL}/${resourcePath}/${params.id}`,
          options: { method: "PATCH", body: JSON.stringify(params.data) },
        };
        if(params.filter?.id) {
          return { url: `${API_URL}/${resourcePath}/${params.filter.id}`};
        }
        return { url: `${API_URL}/${resourcePath}/${params.id}`};
      case CREATE:
        return { 
          url: `${API_URL}/${resourcePath}`,
          options: {method: "POST", body: JSON.stringify(params.data)}}
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const outgoingCustomers = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST:
      const basePath = `${API_URL}/${resourcePath}`
      var urlString = basePath;
      if (params.filter.customer_name != null && params.filter.customer_name.length > 0) {
        urlString = basePath.concat(`?customer_name=${params.filter.customer_name}`)
      }
      if (params.filter.email != null && params.filter.email.length > 0) {
        if (urlString == basePath) {
          urlString = basePath.concat(`?email=${params.filter.email}`)
        }
        else {
          urlString = urlString.concat(`&email=${params.filter.email}`)
        }
      }
      if (urlString == basePath) params.filter = null;
      return { url: urlString }
    case GET_ONE:
      return {
        url: `${API_URL}/${resourcePath}/${params.id}`,
      };
    case UPDATE:
      return {
        url: `${API_URL}/${resourcePath}/${params.id}`,
        options: {method: "PATCH", body: JSON.stringify(params.data)}
      }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingDocumentFormats = (type, resourcePath, params) => {
  switch (type){
    case GET_MANY:
      return {
        url: `${API_URL}/internal/document_formats`
      }
    case GET_ONE:
      return {
        url: `${API_URL}/internal/document_formats`
      }
    case GET_LIST:
      return {
        url: `${API_URL}/internal/document_formats`
      }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingCharge = (type, resourcePath, params) => {
  switch (type){
    case GET_MANY_REFERENCE:
      return {
        url: `${API_URL}/internal/customers/${params.id}/${resourcePath}`
      }
    case GET_ONE:
      return {
        url: `${API_URL}/internal/customers/${params.id[0]}/${resourcePath}/${params.id[1]}`
      }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingStripeCharges = (type, resourcePath, params) => {
  switch (type){
    case GET_MANY_REFERENCE:
      return {
        url: `${API_URL}/internal/customers/${params.target}/${resourcePath}`
      }
    case GET_ONE:
      return {
        url: `${API_URL}/internal/customers/${resourcePath}/${params.id}`
      }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingStripeSubscriptions = (type, resourcePath, params) => {
  switch (type){
    case GET_MANY_REFERENCE:
      return {
        url: `${API_URL}/internal/customers/invoices/${params.target}/${resourcePath}`
      }
    case GET_ONE:
      return {
        url: `${API_URL}/internal/customers/invoices/${params.id}/${resourcePath}`
      }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingDriveAxleUsers = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST:
      const basePath = `${API_URL}/${resourcePath}`
      var urlString = basePath;
      if (params.filter.email != null && params.filter.email.length > 0) {
        urlString = basePath.concat(`?email=${params.filter.email}`)
      }
      else {
        params.filter = null
      }
      return { url: urlString }
    case GET_ONE:
      return {
        url: `${API_URL}/${resourcePath}/${params.id}`,
      };
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingDocuments = (type, resourcePath, params) => {
  switch(type) {
      case GET_LIST:
        const basePath = `${API_URL}/${resourcePath}/search?`
        var urlString = basePath;
        const search_params = new URLSearchParams();

        if(params.filter.uuid != null && params.filter.uuid.length > 0){
          const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
          if(regexExp.test(params.filter.uuid)){
            search_params.append('customer_uuid', params.filter.uuid)
          }
          else{
            search_params.append('client_key', params.filter.uuid)
          }
        }

        if(params.filter.sender_email != null && params.filter.sender_email.length > 0){
          search_params.append('driver_email', params.filter.sender_email)
        }
        if(params.filter.recipient_email != null && params.filter.recipient_email.length > 0){
          search_params.append('recipient_email', params.filter.recipient_email)
        }
        if(search_params.size > 0){
          if((params.filter.start_date != null && params.filter.start_date.length > 0)){
            search_params.append('start_date', params.filter.start_date)
          }
          if((params.filter.start_date != null && params.filter.start_date.length > 0) &&
            (params.filter.end_date != null && params.filter.end_date.length > 0)){
            search_params.append('end_date', params.filter.end_date)
          }
          if(params.filter.downloaded_documents == undefined){
            search_params.append('is_downloaded', false)
          }
          else{
            search_params.append('is_downloaded', true)
          }
        }
        if(search_params.size == 0){
          params.filter = null
        }
        else{
          urlString += search_params;
        }
        return {
          url: urlString
        }
      default:
        return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingApps = (type, resourcePath, params) => {
  switch (type) {
      case GET_LIST:
      case GET_MANY:
          var urlString = `${API_URL}/${resourcePath}`
        return { url: urlString };
      case GET_ONE:
        return {
          url: `${API_URL}/${resourcePath}/${params.id}`,
        };
      case UPDATE:
        var updated_values = selectModifiedValues(params.previousData, params.data)
        return {
          url: `${API_URL}/${resourcePath}/${params.id}`,
          options: {method: "PATCH", body: JSON.stringify(updated_values)}
        }
      default:
        return baseOutgoingRequest(type, resourcePath, params);
    }
}

const outgoingSendgrid = (type, resourcePath, params) => {
  switch(type) {
    case GET_ONE:
      var urlString = `${API_URL}/internal/backend_users/sendgrid_status/${params.id}`
      return {url: urlString}
    case DELETE:
      switch(params.meta){
        case 'Blocks': 
          var urlString = `${API_URL}/internal/backend_users/sendgrid/blocks/${params.id}`
          break;
        case 'Bounce': 
          var urlString = `${API_URL}/internal/backend_users/sendgrid/bounces/${params.id}`
          break;
        case 'Unsubscribe': 
          var urlString = `${API_URL}/internal/backend_users/sendgrid/unsubscribes/${params.id}`
          break;
      }

      return {
        url: urlString,
        options: { method: "DELETE" },
      }
    default: 
      return baseOutgoingRequest(type, resourcePath, params)
  }
}

const incomingSendgrid = (json, type, params) => {
  switch (type) {
    case GET_ONE: 
      return {
        data: Object.assign(json, {"id": json.statuses})
      }
    case DELETE:
      return{
        data: Object.assign(json, {"id": params.id})
      } 
  }
}

const incomingCustomers = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.uuid })),
        total: json.length
      }
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.uuid }),
      }
    case UPDATE:
      return {
        data: Object.assign(json, { "id": json.uuid }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingDriveAxleUsers = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.email })),
        total: json.length
      }
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.email }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingDocumentFormats = (json, type, params) => {
  switch (type) {
    case GET_MANY:
      return {
        data: json.map(x => ({ 
          ...x, 
          "id": x.id, 
          "supports_full_color": x.supports_full_color 
        })),
        total: json.length
      } 
    case GET_LIST:
      const updatedData = json.map(x => ({
        ...x,
        "id": x.id,
        "supports_full_color": !x.supports_full_color,
      }));
      return {
        data: updatedData,
        total: updatedData.length,
      };
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.uuid }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}
const incomingDocuments = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      for(let i = 0; i < json.length; i++){
        json[i]["#"] = i+1;
      }
      return{
        data: json.map(x => Object.assign(x, {"id": x.document_uuid})),
        total: json.length
      }
    case GET_ONE:
      return {data: Object.assign(json, {"id": json.created_at})};
    default:
      return baseIncomingRequest(json,type,params);
  }
}

const incomingCharge = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      return {
        data: json.map(x => Object.assign(x, { "id": x.uuid })),
        total: json.length
      }
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.uuid }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingStripeCharges = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      json.map(x => Object.assign(x, {"created": new Date(x.created * 1000).toLocaleString()}))
      return {
        data: json.map(x => Object.assign(x, { "id": x.id })),
        total: json.length
      }
    case GET_ONE:
      if(json.created) Object.assign(json, {"created": new Date(json.created * 1000).toLocaleString()})
      return {
        data: Object.assign(json, { "id": json.id }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingStripeSubscriptions = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      json.map(x => Object.assign(x, {"created": new Date(x.created * 1000).toLocaleString()}))
      json = [json]
      return {
        data: json.map(x => Object.assign(x, { "id": x.id })),
        total: json.length
      }
    case GET_ONE:
      if(json.created) Object.assign(json, {"created": new Date(json.created * 1000).toLocaleString()})
      return {
        data: Object.assign(json, { "id": json.id }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingClientGroups = (type, params) => {
  switch (type) {
      case GET_LIST:
        return { url: `${API_URL}/internal/client_groups`};
      case GET_MANY:
        return { url: `${API_URL}/internal/client_groups`};
      default:
        return baseOutgoingRequest(type, 'internal/client_groups', params);
    }
}

const incomingApps = (json, type, params) => {
  switch(type) {
    case GET_ONE:
      var ios_here_permissions = {}
      var android_here_permissions = {}

      try {
        ios_here_permissions = decryptHereKey(json.ios_here_key)
      }catch(e) {
        console.log(e)
      }
      try {
        android_here_permissions = decryptHereKey(json.android_here_key)
      }catch(e) {
        console.log(e)
      }

      return {
        data: Object.assign(json,
          {
            "ios_environment_properties": json.ios_environment_properties ? JSON.stringify(json.ios_environment_properties): null,
            "android_environment_properties": json.android_environment_properties ? JSON.stringify(json.android_environment_properties) : null,
            "ios_here_permissions": ios_here_permissions,
            "android_here_permissions": android_here_permissions,
            "android_signing_fingerprints": json.android_signing_fingerprints,
            "id": json.client_key
          })
      }
    case GET_MANY_REFERENCE:
      return {
        data: json.map(x => Object.assign(x, { "id": x.client_key })),
        total: json.length,
      }
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.client_key })),
        total: json.length,
      }
    case GET_LIST:
      if (params.filter && 'q' in params.filter) {
        const query = params.filter.q.toLowerCase();
        json = json.filter(item => 
          item.client_key.toLowerCase().includes(query) || 
          item.name.toLowerCase().includes(query)
        );
      } else {
        json = filterResources(json, params.filter);
      }
      return {
        data: json.map(x => Object.assign(x, {"id": x.client_key })),
        total: json.length,
      }
      case GET_MANY_REFERENCE:
        return {
          data: json.map(x => Object.assign(x, { "id": x.client_key })),
          total: json.length,
        }
      case GET_MANY:
        return {
          data: json.map(x => Object.assign(x, { "id": x.client_key })),
          total: json.length,
        }
      case CREATE:
        var newJson = Object.assign(json, {"id": json.key });
        return ({data: newJson})
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingAppAssets = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      return {
        data: json
      }
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.id }),
      }
    case UPDATE:
      return {
        data: Object.assign(json, { "id": json.url }),
        total: 1,
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingAppAssets = (type, params) => {
  switch (type) {
    case GET_LIST:
      return { url: `${API_URL}/internal/clients/${params.id}/assets`};
    case GET_ONE:
      return { url: `${API_URL}/internal/clients/${params.id}/assets`};
    case UPDATE:
      return { 
        url: `${API_URL}/internal/clients/${params.id}/assets/${params.appAssetName}`,
        options: {
          method: 'PUT'
        }
      }
    default:
      return baseOutgoingRequest(type, `${API_URL}/internal/clients/${params.id}/assets`, params);
  }
}

const incomingAltDashboardLogos = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      const data = json.map(x => {return Object.assign({}, x, { "id": x.filename});});
      return {
        data: data,
        total: data.length,
      };
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.id }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingDocFormatAssociations = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      return {
        data: json.map(x, i => Object.assign(x, { "id": i })),
        total: json.length,
      }
    case GET_ONE:
      return {
        data: Object.assign(json, { "id": json.id }),
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingDocFormatAssociations = (type, params) => {
  switch (type) {
    case GET_LIST:
      return { url: `${API_URL}/internal/clients/document_type_format_associations/${params.id}`};
    case GET_ONE:
      return { url: `${API_URL}/internal/clients/document_type_format_associations/${params.id}`};
    case UPDATE:
      return { 
        url: `${API_URL}/internal/clients/document_type_format_associations/${params.id}`,
        options: { 
          method: "PATCH",
          body: params.body
        }
      }
      default:
      return baseOutgoingRequest(type, `${API_URL}/internal/clients/document_type_format_associations/${params.id}`, params);
  }
}

const outgoingAuditLog = (type, resource, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      const urlString = `${API_URL}/internal/auditlog/customers/${params.filter.uuid}`;
      return { url: urlString };
    default:
      return baseOutgoingRequest(type, resource, params);
  }
};

const incomingGPXFiles = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.url })),
        total: json.length,
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
};
const outgoingGPXFiles = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST:
      var urlString = `${API_URL}/internal/demo_service_gpx_files`
      return { url: urlString};
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}


const incomingAuditLog = (json, type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      return {
        data: json.map((entry) => ({
          ...entry,
          uuid: entry.id,
        })),
        total: json.length,
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
};

const outgoingChangelog = (type, resourcePath, params) => {
  switch (type) {
    case GET_ONE:
      var urlString = `${API_URL}/internal/changelog/${params.filter.from}/${params.id}`;
      return { url: urlString };
    case GET_MANY_REFERENCE:
      var urlString = `${API_URL}/internal/changelog/${params.filter.from}/${params.id}`;
      return { url: urlString};
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingChangelog = (json, type, params) => {
  switch (type) {
    case GET_ONE:
      return {
        data: Object.assign(json, {"id": json.uuid})
      }
    case GET_MANY_REFERENCE:
      return {
        data: json.map(x => Object.assign(x, {"id": x.uuid })),
        total: json.length,
      }
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const incomingPermissions = (json, type, params) => {
  switch (type) {
    case CREATE:
      return {
        data: Object.assign(json, { "id": params.data.group }),
        total: 1,
      }; 
    case UPDATE:
      return {
        data: Object.assign(json, { "id": params.data.group }),
        total: 1,
      };  
      case GET_LIST:
        const isStringsArray = (json) => Array.isArray(json) && json.every(i => typeof i === "string");
    
        if (isStringsArray(json)) {
            const data = json.map((resource, i) => ({ id: i, value: resource }));
            return {
                data: data,
                total: data.length,
            };
        }
    
        const listJson = [];
        for (const [key, value] of Object.entries(json)) {
            let resources = [];
    
            if (value) {
                for (const [k2, v2] of Object.entries(value)) {
                    resources.push({ id: k2, perms: v2 });
                }
            }
    
            listJson.push({ id: key, permissions: resources.length ? resources : null });
        }
    
        return {
            data: listJson,
            total: listJson.length,
        };
    default: 
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingAltDashboardLogo = (type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      return { url: `${API_URL}/internal/clients/${params.id}/alt_dashboard_logos`};
    case GET_ONE:
      return { url: `${API_URL}/internal/clients/${params.id}/alt_dashboard_logos`};
    default:
      return baseOutgoingRequest(type, `${API_URL}/internal/clients/${params.id}/alt_dashboard_logos`, params);
  }
}

const outgoingPermissions = (type, resourcePath, params) => {
  switch(type) {
    case CREATE :
      if(params?.meta === 'createGroup'){
        return{
          url: `${API_URL}/internal/permissions/groups/${params.data.group}`,
          options: { method: "POST" }
        }
      }
      else{
      return {
        url: `${API_URL}/internal/permissions/`,
        options: { method: "POST", body: JSON.stringify(params.data) },
      }

      }
    case UPDATE:
      const { useActive, ...data } = params.data || {};
      const url = useActive
        ? `${API_URL}/internal/permissions/active`
        : `${API_URL}/internal/permissions/update`;
      return {
        url: url,
        options: { method: "PATCH", body: JSON.stringify(data) },
      };
    case GET_ONE:
      return{
        url: `${API_URL}/internal/permissions/${params.id}`
      }
    case GET_LIST:
      if(params?.meta === 'getResources'){
        return{
          url: `${API_URL}/internal/permissions/resources/all`
        }
      }
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (params.filter && !('q' in params.filter)) {
        if(params.filter.group_name != null && emailRegex.test(params.filter.group_name)){
          return{
            url: `${API_URL}/internal/permissions/${params.filter.group_name}`
          }
        }
        else{
          return {
            url: `${API_URL}/internal/permissions/all`
          }
        }
    }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const outgoingRetentionPolicies = (type, resourcePath, params) => {
  switch (type) {
    case GET_ONE:
      var urlString = `${API_URL}/internal/retention_policies/${params.id}`;
      return { url: urlString };
    case UPDATE:
      var payload = selectModifiedValues(params.previousData, params.data);
      payload = (payload == null ? {} : payload);
      return {
        url: `${API_URL}/internal/retention_policies/${params.id}`,
        options: { method: "PATCH", body: JSON.stringify(payload)},
      };      
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingRetentionPolicies = (json, type, params) => {
  switch (type) {
    case GET_ONE:
      return { data: Object.assign(json, { "id": params.id }) };
    case UPDATE:
      return {data: {"id": params.id},}
    default:
      return baseIncomingRequest(json, type, params);
  }
}

const outgoingBackendUserInviteDrafts = (type, resourcePath, params) => {
  switch(type){
    case GET_LIST:
      var urlString = `${API_URL}/${resourcePath}/draft`;
      return {url: urlString};
    case UPDATE:
      var urlString = `${API_URL}/${resourcePath}/draft/${params.id}`;
      return { 
        url: urlString,
        options: { method: "PATCH" }
      };
    case CREATE:
      var urlString = `${API_URL}/${resourcePath}/${params.meta.clientKey}`
      return {
        url: urlString,
        options: { 
          method: "POST",
          body: JSON.stringify(params.data)
        }
      };
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingBackendUserInviteDrafts = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      if (params.filter && !('q' in params.filter)) {
        json = filterResources(json, params.filter);
      }
      return {
        data: json,
        total: json.length,
      };
    default:
      return {
        data: { id: params.id }
      };
  }
}

const outgoingBackendUserMigrationDrafts = (type, resourcePath, params) => {
  switch (type) {
    case CREATE:
      var urlString = ""
      if (params.data.account_type === "client") {
        urlString = `${API_URL}/${resourcePath}/${params.data.email}/client/${params.data.key}`
      } else if (params.data.account_type === "customer") {
        urlString = `${API_URL}/${resourcePath}/${params.data.email}/customer/${params.data.uuid}`
      }
      return {
        url: urlString,
        options: { method: "POST" }
      }
    case GET_LIST:
      var urlString = `${API_URL}/${resourcePath}/drafts`;
      return { url: urlString };
    case UPDATE:
      var urlString = `${API_URL}/${resourcePath}/drafts/${params.id}`;
      return { 
        url: urlString,
        options: { method: "PATCH" }
      };
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingBackendUserMigrationDrafts = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      if (params.filter && !('q' in params.filter)) {
        json = filterResources(json, params.filter);
      }
      return {
        data: json,
        total: json.length,
      };
    default:
      return {
        data: { id: params.id }
      };
  }
}

const outgoingIntegrationMetadataFormats = (type, resourcePath, params) => {
  switch (type) {
    case GET_ONE:
      var urlString = "";
      if (params.meta.account_type === "client") {
        urlString = `${API_URL}/${resourcePath}/client/${params.id}`;
      } else if (params.meta.account_type === "customer") {
        urlString = `${API_URL}/${resourcePath}/customer/${params.id}`;
      }

      return { url: urlString };
    case GET_LIST:
      var urlString = `${API_URL}/${resourcePath}`;
      return { url: urlString };
    case GET_MANY:
      var urlString = `${API_URL}/${resourcePath}`;
      return { url: urlString };
    case UPDATE:
      var urlString = "";
      if (params.data.account_type === "client") {
        urlString = `${API_URL}/${resourcePath}/client/${params.id}`;
      } else if (params.data.account_type === "customer") {
        urlString = `${API_URL}/${resourcePath}/customer/${params.id}`;
      }

      return {
        url: urlString,
        options: {
          method: "PATCH",
          body: JSON.stringify({ integration_metadata_format: params.data.integration_metadata_format })
        }
      }
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
};

const incomingIntegrationMetadataFormats = (json, type, params) => {
  switch (type) {
    case GET_ONE:
      return { data: Object.assign(json, { "id": json.name }) };
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.name })),
        total: json.length,
      };
    case GET_MANY:
      return {
        data: json.map(x => Object.assign(x, { "id": x.name })),
      };
    case UPDATE:
      return {
        data: Object.assign(json, { "id": json.name })
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
};

const outgoingEmailVerifications = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST:
      var urlString = `${API_URL}/internal/backend_users/${params.filter.email}/verifications`
      return { url: urlString };
    case CREATE:
      return {
        url: `${API_URL}/${resourcePath}/${params.data.email}`,
        options: { method: "PUT", body: JSON.stringify({ message: params.data.message }) },
      };
    default:
      return baseOutgoingRequest(type, resourcePath, params);
  }
};

const incomingEmailVerifications = (json, type, params) => {
  switch(type) {
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.verification_sent_at })),
        total: json.length,
      };
    case CREATE:
      return {
        data: { "id": params.data.email }
      };
    default:
      return baseIncomingRequest(json, type, params);
  }
};

const outgoingCustomerUnsubscribeDrafts = (type, resourcePath, params) => {
  const iso_date = params.data?.unsubscribeDate ? new Date(params.data.unsubscribeDate).toISOString() : null;
  switch (type) {
    case CREATE:
      return {
        url: `${API_URL}/${resourcePath}/${params.data.uuid}`,
        options: {
          method: "POST",
          body: JSON.stringify({
            preserve_backend_user_email:
              params.data.preserve_backend_user_email,
            cancellation_date:
              iso_date,
          }),
        },
      };
    case GET_LIST:
      return { url: `${API_URL}/${resourcePath}/drafts` };
    case UPDATE:
      return {
        url: `${API_URL}/${resourcePath}/drafts/${params.id}`,
        options: { method: "PATCH" },
      };
    default:
      baseOutgoingRequest(type, resourcePath, params);
  }
};

const incomingCustomerUnsubscribeDrafts = (json, type, params) => {
  switch (type) {
    case CREATE:
      return { data: { id: params.data.uuid } };
    case GET_LIST:
      if (params.filter && !("q" in params.filter)) {
        json = filterResources(json, params.filter);
      }
      return {
        data: json,
        total: json.length,
      };
    case UPDATE:
      return { data: { id: params.id } };
    default:
      baseIncomingRequest(json, type, params);
  }
};

const outgoingCustomerKeyRotationDrafts = (type, resourcePath, params) => {
  switch (type) {
    case CREATE:
      return {
        url: `${API_URL}/${resourcePath}/${params.data.uuid}`,
        options: { method: "POST"}
      };
    case GET_LIST:
      return {url: `${API_URL}/${resourcePath}/drafts`};
    case UPDATE:
      return {
        url: `${API_URL}/${resourcePath}/drafts/${params.id}`,
        options: { method: "PATCH" }, 
      };
    default:
      baseOutgoingRequest(type, resourcePath, params);
  }
};

const incomingCustomerKeyRotationDrafts = (json, type, params) => {
  switch (type) {
    case CREATE:
      return { data: { id: params.data.uuid } };
    case GET_LIST:
      if (params.filter && !("q" in params.filter)) {
        json = filterResources(json, params.filter);
      }
      return {
        data: json,
        total: json.length,
      };
    case UPDATE:
      return { data: { id: params.id } };
    default:
      baseIncomingRequest(json, type, params);
  }
}

const outgoingAdministratorGroups = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST:
      return { url: `${API_URL}/${resourcePath}` };
    default:
      baseOutgoingRequest(type, resourcePath, params);
  };
};

const incomingAdministratorGroups = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      let data = Object.keys(json).map(key => Object.assign({id: key}, json[key]));
      return {
        data: data,
        total: data.length
      };
    default:
      baseIncomingRequest(json, type, params);
  };
};

const outgoingIssueReportDownload = (type, resourcePath, params) => {
  switch (type) {
    case GET_ONE:
      return {url: params.url}
    default:
      baseOutgoingRequest(type, resourcePath, params) 
  }
}

const incomingIssueReportDownload = (json, type, params) => {
  switch (type) {
    case GET_ONE:
      return { data: Object.assign(json, { "id": json.url }) };
      default:
        baseIncomingRequest(json, type, params);
  }
}

const outgoingIssueReports = (type, resourcePath, params) => {
  switch (type) {
    case GET_ONE:
      return { url: `${API_URL}/${resourcePath}/${params.id}`,
    }
    case GET_LIST:
      return { url: `${API_URL}/${resourcePath}/${params.meta.client_key}/${params.meta.username}`,
    }
    default:
      baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingIssueReports = (json, type, params) => {
  switch (type) {
    case GET_ONE:
      return { data: Object.assign(json, { "id": json.url }) };
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.uuid, "report_time": x.report_time})),
        total: json.length
      }
    default:
      baseIncomingRequest(json, type, params);
  }
}

const outgoingTripRoutes = (type, resourcePath, params) => {
  switch (type) {
    case GET_LIST:
      return { 
        url: `${API_URL}/${resourcePath}/${params.filter.clientKey}/${params.filter.username}/?start=${params.filter.start}&end=${params.filter.end}`,
      }
    default:
      baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingTripRoutes = (json, type, params) => {
  switch (type) {
    case GET_LIST:
      return {
        data: json,
        total: json.length
      }
    default:
      baseIncomingRequest(json, type, params);
  }
}

const outgoingTripPlans = (type, resourcePath, params) => {
  switch (type) {
    case GET_ONE:
      return { 
        url: `${API_URL}/api/v1/${resourcePath}/${params.filter.username}/${params.filter.loadId}/${params.filter.uuid}`,
      }
    case GET_LIST:
      return { 
        url: `${API_URL}/api/v1/${resourcePath}/${params.filter.username}/${params.filter.loadId}`,
      }
    default:
      baseOutgoingRequest(type, resourcePath, params);
  }
}

const incomingTripPlans = (json, type, params) => {
  // Ensure json is parsed if it arrives as a string
  if (typeof json === "string") {
    json = JSON.parse(json);
  }
  switch (type) {
    case GET_ONE:
      return { data: Object.assign(json, { "id": json.uuid }) };
    case GET_LIST:
      return {
        data: json.map(x => Object.assign(x, { "id": x.uuid})),
        total: json.length
      }
    default:
      baseIncomingRequest(json, type, params);
  }
};

const outgoingRoutes = (type, resourcePath, params) => {
  switch(type){
    case GET_ONE:
      return {
        url: `${API_URL}/${resourcePath}/${params.filter.id}?chunk_size=50&chunking_type=two_step`
      }
    default:
      baseOutgoingRequest(type, resourcePath, params)
  }
}
const incomingRoutes = (json, type, params) => {
  switch(type){
    case GET_ONE:
      return { data: Object.assign(json, { "id": params.filter.id })}
    default:
      baseIncomingRequest(json, type, params);
  } 
}

const outgoingBackendUserRoleDrafts = (type, resourcePath, params) => {
  switch(type){
    case CREATE:
    case DELETE:
      const requestBody = params.meta.data ? params.meta.data : params.data;
      return {
        url: `${API_URL}/internal/backend_users/${params.meta.email}/roles`,
        options: { 
          method: "POST",
          body: JSON.stringify(requestBody)
        }
    };
    case GET_LIST:
      return {url: `${API_URL}/internal/backend_users/${params.meta.email}/roles/drafts`};
    case UPDATE:
      return {
        url: `${API_URL}/internal/backend_users/${params.meta.email}/roles/drafts/${params.id}`,
        options: { method: "PATCH" }, 
      };
    default:
      baseOutgoingRequest(type, resourcePath, params)
  }
}

const incomingBackendUserRoleDrafts = (json, type, params) => {
  switch(type){
    case CREATE:
    case DELETE:
      return { data: { id: json.message } };
    case GET_LIST:
      if (params.filter && !("q" in params.filter)) {
        json = filterResources(json, params.filter);
      }
      return {
        data: json,
        total: json.length,
      };
    case UPDATE:
      return { data: { id: params.id } };
    default:
      baseIncomingRequest(json, type, params);
  }
}

const outgoingTriggerBuilds = (type, resourcePath, params) => {
  switch(type){
    case UPDATE:
      return {
        url: `${API_URL}/api/v1/apps/${params.meta.tag}/${params.meta.platform}`,
        options: { method: "PUT"}
      }
    default: baseOutgoingRequest(type, resourcePath, params)
  }
}

const incomingTriggerBuilds = (json, type, params) => {
  switch(type){
    case UPDATE: 
    let composite = `${params.meta.platform}/${params.meta.tag}`
    return {
      data: Object.assign(json, { "id": composite })
    };
    default:
      baseIncomingRequest(json, type, params)
  }
}

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. "UPDATE"
 * @param {String} resource Name of the resource to fetch, e.g. "posts"
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (resource) {
    case 'clients':
      return outgoingClients(type, params);
    case 'app_assets':
      return outgoingAppAssets(type, params);
    case 'alt_dashboard_logos':
      return outgoingAltDashboardLogo(type, params);
    case 'client_groups':
      return outgoingClientGroups(type, params);
    case 'billing_accounts':
      return outgoingBillingAccounts(type, `internal/${resource}`, params);
    case 'billing_accounts_v2':
      return outgoingBillingAccounts(type, `internal/${resource}`, params);
    case 'contract_structures':
      return outgoingBillingContractStructures(type, `internal/${resource}`, params);
    case 'contract_structure_products':
      return outgoingBillingContractStructureProducts(type, `internal/${resource}`, params);
    case 'per_load_billing_structure':
      return outgoingPerLoadBillingStructure(type, `internal/${resource}`, params);
    case 'account_contracts':
      return outgoingBillingAccountContracts(type, `internal/${resource}`, params);
    case 'price_sheets':
      return outgoingBillingPriceSheets(type, `internal/${resource}`, params);
    case 'price_sheet_items':
      return outgoingBillingPriceSheetItems(type, `internal/${resource}`, params);
    case 'products':
      return outgoingBillingProducts(type, `internal/${resource}`, params);
    case 'customers':
      return outgoingCustomers(type, `internal/${resource}`, params)
    case 'scanning_users':
      return outgoingDriveAxleUsers(type, `internal/${resource}`, params)
    case 'documents':
      return outgoingDocuments(type, `internal/${resource}`, params)
    case 'backend_users':
      return outgoingBackendUser(type, `${resource}`, params);
    case 'charges':
      return outgoingCharge(type, resource, params);
    case 'stripe_charges':
      return outgoingStripeCharges(type, resource, params);
    case 'stripe_subscriptions':
    return outgoingStripeSubscriptions(type, resource, params);
    case 'document_types': 
      return outgoingDocumentFormats(type, resource, params); 
    case 'apps':
      return outgoingApps(type, `internal/${resource}`, params)
    case 'apple_accounts':
      return baseOutgoingRequest(type, `internal/${resource}`, params);
    case 'changelog':
      return outgoingChangelog(type, `${resource}`, params);
    case 'auditlog' :
      return outgoingAuditLog(type, `${resource}`, params);
    case 'retention_policies':
      return outgoingRetentionPolicies(type, `internal/${resource}`, params);
    case 'backend_user_migration_drafts':
      return outgoingBackendUserMigrationDrafts(type, 'internal/migrate_backend_user', params)
    case 'backend_user_invite_drafts':
      return outgoingBackendUserInviteDrafts(type, 'internal/clients/invite', params);
    case 'integration_metadata_formats':
      return outgoingIntegrationMetadataFormats(type, `internal/${resource}`,  params);
    case 'email_verifications':
      return outgoingEmailVerifications(type, 'internal/backend_users/verification', params);
    case 'customer_unsubscribe_drafts':
      return outgoingCustomerUnsubscribeDrafts(type, 'internal/customers/unsubscribe', params);
    case 'administrator_groups':
      return outgoingAdministratorGroups(type, `internal/${resource}`, params);
    case 'customer_key_rotation_drafts':
      return outgoingCustomerKeyRotationDrafts(type, 'internal/customers/rotate_key', params);
    case 'internal_permissions':
      return outgoingPermissions(type, 'internal/permissions/all', params);
    case 'gpx_files':
      return outgoingGPXFiles(type, `internal/demo_service_gpx_files`, params);
    case 'document_type_format_associations':
      return outgoingDocFormatAssociations(type, params);
    case 'issue_report':
      return outgoingIssueReports(type, `internal/issue_report`, params);
    case 'issue_report_download':
      return outgoingIssueReportDownload(type, `${resource}`, params);
    case 'trip_routes':
      return outgoingTripRoutes(type, `internal/${resource}`, params);
    case 'trip_plans':
      return outgoingTripPlans(type, `${resource}`, params);
    case 'routes':
      return outgoingRoutes(type, `internal/${resource}`, params);
    case 'sendgrid':
      return outgoingSendgrid(type, `${resource}`, params);
    case 'backend_user_role_drafts':
      return outgoingBackendUserRoleDrafts(type, `${resource}`, params);
    case 'trigger_builds':
      return outgoingTriggerBuilds(type, resource, params);
    default:
      return outgoingClientResource(type, `${resource}`, params);
  }
};

/**
 * @param {Object} json HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. "UPDATE"
 * @param {String} resource Name of the resource to fetch, e.g. "posts"
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (json, type, resource, params) => {
  switch (resource) {
    case 'clients':
      return incomingClients(json, type, params);
    case 'client_groups':
      return incomingClientGroups(json, type, params);
    case 'apps':
      return incomingApps(json, type, params);
    case 'apple_accounts':
      return incomingAppleAccounts(json, type, params);
    case 'billing_accounts':
      return incomingBillingAccounts(json, type, params);
    case 'billing_accounts_v2':
      return incomingBillingAccounts(json, type, params);
    case 'contract_structures':
      return incomingBillingContractStructures(json, type, params);
    case 'contract_structure_products':
      return incomingBillingContractStructureProducts(json, type, params);
    case 'per_load_billing_structure':
      return incomingPerLoadBillingStructure(json, type, params);
    case 'account_contracts':
      return incomingBillingAccountContracts(json, type, params);
    case 'price_sheets':
      return incomingBillingPriceSheets(json, type, params);
    case 'price_sheet_items':
      return incomingBillingPriceSheetItems(json, type, params);
    case 'products':
    return incomingBillingProducts(json, type, params);
    case 'customers':
      return incomingCustomers(json, type, params);
    case 'scanning_users':
      return incomingDriveAxleUsers(json, type, params);
    case 'documents':
      return incomingDocuments(json, type, params);
    case 'menus':
    case 'dashboards':
    case 'actions':
    case 'screens':
    case 'forms':
      return incomingClientResource(json, type, resource, params);
    case 'app_assets':
      return incomingAppAssets(json, type, params);
    case 'alt_dashboard_logos':
      return incomingAltDashboardLogos(json, type, params);
    case 'backend_users':
      return incomingBackendUsers(json, type, params);
    case 'charges':
      return incomingCharge(json, type, params);
    case 'stripe_charges':
      return incomingStripeCharges(json, type, params);
    case 'stripe_subscriptions':
      return incomingStripeSubscriptions(json, type, params);
    case 'document_types': 
      return incomingDocumentFormats(json, type, params);
    case 'errors':
      return incomingClientResource(json, type, resource, params);
    case 'changelog':
      return incomingChangelog(json, type, params);
    case 'auditlog':
      return incomingAuditLog(json, type, params);
    case 'retention_policies':
      return incomingRetentionPolicies(json, type, params);
    case 'backend_user_migration_drafts':
      return incomingBackendUserMigrationDrafts(json, type, params);
    case 'backend_user_invite_drafts':
      return incomingBackendUserInviteDrafts(json, type, params);
    case 'integration_metadata_formats':
      return incomingIntegrationMetadataFormats(json, type, params);
    case 'email_verifications':
      return incomingEmailVerifications(json, type, params);
    case 'customer_unsubscribe_drafts':
      return incomingCustomerUnsubscribeDrafts(json, type, params);
    case 'administrator_groups':
      return incomingAdministratorGroups(json, type, params);
    case 'customer_key_rotation_drafts':
      return incomingCustomerKeyRotationDrafts(json, type, params);
    case 'internal_permissions': 
      return incomingPermissions(json, type, params);
    case 'gpx_files':
      return incomingGPXFiles(json, type, params);
    case 'document_type_format_associations':
      return incomingDocFormatAssociations(json, type, params);
    case 'issue_report':
      return incomingIssueReports(json, type, params);
    case 'issue_report_download':
      return incomingIssueReportDownload(json, type, params);
    case 'trip_routes':
      return incomingTripRoutes(json, type, params);
    case 'trip_plans':
      return incomingTripPlans(json, type, params);
    case 'routes':
      return incomingRoutes(json, type, params);
    case 'sendgrid':
      return incomingSendgrid(json, type, params);
    case 'backend_user_role_drafts':
      return incomingBackendUserRoleDrafts(json, type, params);
    case 'trigger_builds':
      return incomingTriggerBuilds(json, type, params);
    default:
      return baseIncomingRequest(json, type, params);
  }
};

const sendBackendUserInvite = (email, firstName, lastName, clientKey) => {
    var requestOptions = {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({email: email, first_name: firstName, last_name: lastName})
    };
    var requestUrl = `${API_URL}/internal/clients/invite/${clientKey}`

    fetch(requestUrl, requestOptions)
      .then(response => {
        if(response.ok) {
          response.json().then(resp => {
           alert(`${email} was sucessfully invited to be a backend user for ${clientKey}`);
          });
        }
          else {
            alert(`${email}'s invite failed. Status: ${response.status}`);
          }
        });
}

const sendRecvDataProviderRequest = (type, resource, url, options, params) => {
  var resourcesWithoutGetOne = ['menus','dashboards','actions','screens','forms','errors'];
  const emptyResponse = {data:{"id":params.id}};
  if (type === "GET_ONE" && resourcesWithoutGetOne.indexOf(resource) >= 0) {
    return new Promise(function(resolve, reject){ resolve(emptyResponse)});
  }

  if (type === "GET_ONE" && resource == 'per_load_billing_structure') {
    return new Promise(function(resolve, reject){ resolve(emptyResponse)});
  }

  // handles the case where no customer filter is set s/t we don't send a bad request but can still maintain the IA UI
  else if((resource == 'customers' || resource == 'scanning_users' || resource == 'documents') && params.filter == null && type == "GET_LIST") {
    return new Promise(function(resolve,reject) {
      resolve(convertHTTPResponseToDataProvider([{key:"empty"}], type, resource, params))
    });
  }

  if ((params.data==undefined)&&(type=="UPDATE")&&(resource=="customers")) {
    return new Promise((resolve, reject) => { resolve(emptyResponse) });
  }

  else {
    return fetch(url, options).then(async response => {
        let error = errorHandler(response, resource);
        if(type == 'CREATE' && resource == 'clients' && response.status == 201) {
          var body = JSON.parse(options.body)
          if(body.hasOwnProperty("invite_emails")) {
            body.invite_emails.forEach(invite => {
              sendBackendUserInvite(invite.email, invite.first_name, invite.last_name, body.key)
            })
          }
        }
        if ([404, 400, 500].includes(response.status)) {
          let body = await response.json();
          const empty = handleEmpty(resource, type, response.status, body);
          if (empty !== null){ 
            return empty; 
          }
        }
        
        if (error !== response){ return error;}
        if(resource === 'trigger_builds') return null
        return response.json()
    }).then(json => convertHTTPResponseToDataProvider(json, type, resource, params));
  }
}

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
 const dataProvider = (type, resource, params, authorization) => {
  if(localStorage.getItem('searchCriteria')){localStorage.removeItem('searchCriteria')}
  var { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
  if (!options) { options = {}; }
  if (!options.headers) {
    options.headers = new Headers();
  }
  if (resource === 'trip_plans')
    options.headers.set("Eleos-Admin-Client-Key", params.filter.clientKey);
  if (resource === 'trigger_builds')
    options.headers.set("Eleos-Admin-Client-Key", params.meta.clientKey);
  if(resource !== 'issue_report_download')
    options.headers.set("Authorization", authorization);
  options.headers.set("Content-Type", "application/json");
  return sendRecvDataProviderRequest(type, resource, url, options, params);
 };

 export default dataProvider;
 export { convertDataProviderRequestToHTTP }
