import React, {
  useEffect,
  useState
} from "react";
import { useUpdate } from "react-admin";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { useNotify } from 'react-admin';
import Link from '@mui/material/Link';
import { Checkbox } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { renderIfPermissions } from "../api/permissionManager";

const API_URL = (window.frameElement && window.frameElement.getAttribute("data-api-url")) ||
  (process && process.env && process.env.REACT_APP_API_URL);
import "./AppButton.css"
import { FormControl } from "@mui/material";
import { checkClientAssets, checkIconReqs, checkLogoReqs, checkImageRequirements } from '../resources/assetChecks';

const StyledButton = styled(Button)({
  width: '10rem',
  height: '2.5rem',
  marginBottom: '20px'
});

const StyledTableCell = styled(TableCell)({
  '&:hover': {
    background: "#eee"
  },
  cursor: "pointer"
});

export const TriggerBuildForm = (props) => {
  const [permissions, setPermissions] = useState(props.permissions);
  const [key, setKey] = useState(props.record.key);
  const [ios, setIos] = useState(false);
  const [android, setAndroid] = useState(true);
  const [tagName, setTagName] = useState('');
  const [releaseChoice, setReleaseChoice] = useState('');
  const [releases, setReleases] = useState([]);
  const [update] = useUpdate();
  const notify = useNotify();

  const refreshTags = (platform) => {
    fetch(`${API_URL}/internal/app_releases`, {
      method: "GET",
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => res.json()).then((data) => setReleases(data));
  };

  useEffect(() => {
    refreshTags("android");
  }, []);

  const androidToggle = () => {
    setAndroid(true)
    setIos(false)
    setTagName("")
    releases.forEach((release) => {
      if (release.release_name === releaseChoice) {
        setTagName(release.android_tag)
      }
    })
  }

  const iosToggle = () => {
    setAndroid(false)
    setIos(true)
    setTagName("")
    releases.forEach((release) => {
      if (release.release_name === releaseChoice) {
        setTagName(release.ios_tag)
      }
    })
  }

  const triggerBuild = async (platform) => {
    if (tagName === "") {
      notify("Error: You must select a release.", {type: 'error'});
      return;
    }
    try {
      const data = await checkClientAssets(key);

      const [appIconSuccess, dashboardLogoSuccess, loginLogoSuccess] = await Promise.all([
        checkImageRequirements(data.app_icon_url, checkIconReqs, 'app-icon'),
        checkImageRequirements(data.dashboard_logo_url, checkLogoReqs, 'dashboard-logo'),
        checkImageRequirements(data.login_logo_url, checkLogoReqs, 'login-logo')
      ]);

      if (appIconSuccess && dashboardLogoSuccess && loginLogoSuccess) {
        try {
          await update('trigger_builds', {
            id: `${platform}/${tagName}`,
            data: {},
            meta: {
              tag: tagName,
              platform,
              clientKey: key
            }
          });
          notify(`Build triggered for ${platform} with tag ${tagName}`, {type: 'success'});
        } catch (error) {
          console.error('An error occurred while triggering build:', error);
          notify('Failed to trigger build. Please try again.', {type: 'error'});
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmit = () => {
    if (ios) {
      triggerBuild('ios');
    }
    if (android) {
      triggerBuild('android');
    }
  }

  const renderIosSwitch = () => {
    if (key.length < 4 || key.substring(key.length - 4) !== '-dev') {
      return (
        <FormControlLabel
          control={
            <Switch
              checked={ios}
              onChange={iosToggle}
              name="ios"
              color="primary"
            />
          }
          label="iOS"
        />
      );
    }
  }

  const onTagInputChange = (text) => {
    setReleaseChoice(text)
    releases.forEach((release) => {
      if (android && release.release_name === text) {
        setTagName(release.android_tag)
      }
      else if (ios && release.release_name === text) {
        setTagName(release.ios_tag)
      }
    })
    if (text === "Select a release")
      setTagName("")
  }

  const renderBuildField = () => {
    let options = releases.map((opts) => opts.release_name)
    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <select className="tag-input" onChange={e => onTagInputChange(e.target.value)}>
            <option >Select a release</option>
            {options && options.map((option, i) => (
              <option key={i} value={option.value}>{option}</option>
            ))}
          </select>
        </FormControl>
      </div>
    );
  }
  
  return (
    <FormGroup style={{ width: "20%" }}>
      {renderIosSwitch()}
      <FormControlLabel
        control={
          <Switch
            checked={android}
            onChange={androidToggle}
            name="android"
            color="primary"
          />
        }
        label="Android"
      />
      <div style={{ width: "100%" }}>
        {renderBuildField()}
        <br />
        {renderIfPermissions(permissions, "client_assets", "write",
          <Button color="primary" variant="contained" size="medium" sx={{ width: '10rem', height: '2.5rem' }} onClick={handleSubmit}>Trigger Build</Button>
        )}
      </div>
      <br />
      <BuildStatusDisplay client_key={key} />
    </FormGroup>
  )
}


const BuildStatusDisplay = (props) => {
  const [data, setData] = useState([]);
  const [keys] = useState(
    ["build_status", "is_published", "key", "name", "build_artifacts", "circle_url", "triggered_at", "triggered_by", "tag", "error_info"],
  )
  const endpoint = `${API_URL}/internal/apps/status`

  const checkBuildStatus = (client_key, setData) => {
    notify("Refreshing build status...", 'info')
    // get initial list of builds
    fetch(`${API_URL}/internal/apps/${client_key}/statuses`, {
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(response => response.json())
      // for each build retrieved, make sure they are up to date
      .then(data => {
        data.forEach((build) => {
          getStatus(build.pipeline_uuid)
            .then(status => {
              if (status.errors) {
                build.build_status = status.errors
              }
            })
        })
      });

    // refresh list of builds with updated status
    fetch(`${API_URL}/internal/apps/${client_key}/statuses`, {
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(response => response.json())
      .then(data => {
        data.forEach((build) => {
          if (build.build_status == 'null') {
            getStatus(build.pipeline_uuid)
              .then(status => {
                if (status.errors) {
                  build.build_status = status.errors
                }
                else {
                  build.build_status = "pending..."
                }
              })
          }
        });
        setData(data);
      });
  }

  useEffect(() => {
    checkBuildStatus(props.client_key, setData)
  }, [props.client_key])

  const notify = useNotify()

  const getStatus = async (uuid) => {
    return await fetch(`${endpoint}/${uuid}`, {
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
  }

  const changePublishStatus = (event, pipeline_uuid) => {
    fetch(`${API_URL}/internal/apps/${pipeline_uuid}/published/${event.target.checked}`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
  }

  const renderTableCell = (row, key) => {
    switch (key) {
      case "build_status":
        return (<StyledTableCell onClick={() => getStatus(row.pipeline_uuid)}>{row[key]}</StyledTableCell>)
      case "circle_url":
        return (<StyledTableCell onClick={() => window.open(row[key])}>{row[key]}</StyledTableCell>)
      case "tag":
        return row.branch === null || undefined ? (<TableCell>{row[key]}</TableCell>) : (<TableCell>{row.branch}</TableCell>)
      case "build_artifacts":
        const build_files = row[key]
        if (Object.keys(build_files).length === 0) {
          return <TableCell sx={{ whiteSpace: 'nowrap' }}>No Build Files</TableCell>
        } else {
          return (<TableCell sx={{ whiteSpace: 'nowrap' }}>{
            Object.keys(build_files).map((k, i) => (
              <p key={i}>
                <span><Link href={build_files[k]} download>{k.slice(0, 3).toUpperCase()} Build File</Link></span>
              </p>
            ))}
          </TableCell>
          )
        }
      case "is_published":
        const is_published = row[key]
        return (<TableCell><Checkbox name="published status" defaultChecked={is_published} onChange={(e) => changePublishStatus(e, row['pipeline_uuid'])} /></TableCell>)
      case "error_info":
        const error_info = row[key];
        if (error_info) { console.error(error_info) }
        if (error_info === null || error_info.length === 0) {
          return (<TableCell>None</TableCell>);
        } else {
          return (
            <TableCell>
              {(() => {
                const blob = new Blob([error_info[0]], {
                  type: "text/plain",
                });
                const url = URL.createObjectURL(blob);
                return (
                  <Link
                    href={url}
                    target="_blank"
                    display="block"
                    download="build_error"
                  >
                    Build Error
                  </Link>
                );
              })()}
            </TableCell>
          );
        }
      default:
        return (<TableCell>{row[key]}</TableCell>)
    }
  }

  if (data.length === 0) return (<p>no builds yet :(</p>)
  return (
    <div>
      <StyledButton color="primary" variant="contained" onClick={() => checkBuildStatus(props.client_key, setData)}>Refresh</StyledButton>
      <TableContainer>
        <Table>
          <TableHead style={{ textTransform: "uppercase" }} >
            <TableRow>
              {keys.map((key) => (<TableCell key={key}>{key.replaceAll('_', ' ')}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.pipeline_uuid}>
                {keys.map((key) => renderTableCell(row, key))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}